body {
    background-color: #f5f5f5;
}

/*.container {*/
/*    background-color: #ffffff;*/
/*}*/

a:hover {
    color: #1e4c92;
}

#container-carousel {
    padding: 0;
}

.carousel-control-prev-icon, .carousel-control-next-icon {
    background-color: #1e4c92;
}

.carousel .carousel-inner .carousel-item img {
    max-height: 500px;
    min-width: auto;
}

.carousel-caption {
    background: rgba(30, 76, 146, 0.35);
    width: 100%;
    position: absolute;
    left: 0;
    bottom: 0;
    padding-bottom: 5%;
}